import React from "react";
import RegistrationField from "../components/RegistrationField";

function Register() {
  return (
    <main>
      <RegistrationField />
    </main>
  );
}

export default Register;
